import React from 'react'

function Map() {
    return (
        <>
         <div className="google-map">

            <iframe  title="My Address" src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d335900.93392687745!2d2.3504871190777603!3d48.87296719673322!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sru!2s!4v1394030722365" ></iframe>

        </div>   
        </>
    )
}

export default  Map
